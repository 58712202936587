.reset-password-request-modal {
    .modal {
        .modal-header {
            margin: 0;
            padding: 0 rem(12);

            @include media-breakpoint-up(md) {
                padding: 0 rem(48);
                color: $primary-black;
                margin: 0;
                font-weight: $font-weight-regular;
            }

            .modal-header-title {
                margin: rem(64) 0 rem(48);
                padding: 0;
                letter-spacing: 0.08em;

                @include media-breakpoint-down(md) {
                    margin: rem(56) 0 rem(40);
                    font-size: $font-size-4;
                    letter-spacing: 0.05em;
                }
            }

            .close {
                right: rem(16);
                top: rem(24);
            }
        }

        .modal-body {
            @include media-breakpoint-up(md) {
                padding: 0 rem(48);
                font-size: rem(24);
            }
        }
    }

    .store-cross {
        margin: 0;
        text-align: right;
        opacity: 1;
        z-index: 1;
        position: absolute;
        right: rem(16);
        top: rem(16);

        @include media-breakpoint-up(md) {
            right: rem(48);
            top: rem(32);
            padding: 0;
        }
    }

    .form-group.custom .float-label {
        line-height: rem(16);
        letter-spacing: 0.08em;
        margin-bottom: rem(8);
        bottom: rem(-4);
    }



    input:focus~.float-label,
    input:not(:placeholder-shown)~.float-label {
        top: rem(8);
        margin-bottom: rem(8);
    }

    .form-group.custom {
        .form-control {
            color: $primary-black;
            border-bottom: rem(1) solid $primary-black;
        }

        .invalid-feedback {
            margin-top: rem(8);
        }
    }
}

.request-password-title {
    margin: rem(40) 0;
    font-family: $font-primary;
    font-size: rem(32);
    line-height: rem(40);
    color: $primary-black;
    text-align: center;
    border: 0;
    padding-bottom: 0;
    letter-spacing: normal;
    margin: rem(40) 0 rem(40);

    @include media-breakpoint-up(md) {
        margin: rem(96) 0 rem(40);
    }
}

.request-password-description {
    margin-bottom: rem(40);
    line-height: rem(20);
    color: $primary-black;
}

.send-email-btn {
    #submitEmailButton {
        font-family: $font-medium;
        font-weight: $font-weight-medium;
        font-size: $font-size-3;
        line-height: rem(20);
        letter-spacing: 0.08em;
        position: relative;
        bottom: initial;
        height: rem(48);
        margin-top: rem(32);
        align-items: center;
        padding: 0;
        width: 100%;

        :not(.login-page) & {
            margin-top: rem(32);
        }

        &:disabled {
            color: $custom-control-inactive-text-color;
        }
    }

}

.reset-password-success {
    .success-message+.close-modal-btn {
        border: rem(1.5) solid $primary-black;
        position: relative;
        bottom: initial;
        margin-top: rem(32);
        line-height: rem(20);

        &:hover {
            background-color: $primary-black;
            border-color: $primary-black;
            color: $white;
        }
    }
}

.request-password-description {
    margin-bottom: 0;
    font-family: $font-primary;
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: $font-size-14;
    line-height: rem(20);
    align-items: center;
    color: $primary-black;
    letter-spacing: 0.05em;
    padding-bottom: rem(40);

    :not(.login-page) & {
        padding-bottom: 0;
        margin-bottom: rem(40);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(24);
        }
    }


    @include media-breakpoint-up(md) {
        .request-password-title {
            margin: rem(96) 0 rem(40);
        }
    }
}

.reset-password-success {
    display: none;
}

.request-success {
    .reset-password-form {
        display: none;
    }

    .reset-password-success {
        display: block;
        margin-top: 0;
    }
}

.success-message {
    margin-top: rem(40);
    margin-bottom: 0;
    line-height: rem(20);
    letter-spacing: 0.05em;
}

.close-modal-btn {
    margin-top: rem(40);
    height: rem(56);
}

@include media-breakpoint-up(md) {
    .success-message {
        margin-top: rem(40);
        line-height: rem(20);
        letter-spacing: 0.05em;
        font-size: $font-size-14;
    }

    .close-modal-btn {
        margin-top: rem(32);
        line-height: rem(20);
        letter-spacing: 0.08em;
    }

    .request-success {
        .reset-password-success {
            margin-top: rem(1);
            margin-top: rem(48);
        }
    }
}

@include media-breakpoint-down(md) {
    .request-success {
        .reset-password-success {
            margin-top: rem(48);
        }
    }
}

.icon-opacity {
    opacity: 0.9;
}

.container:not(.login-page) {
    &#requestPasswordResetModal {
        padding-bottom: rem(64);

        @include media-breakpoint-up(md) {
            padding-bottom: rem(104);
        }
    }
}

.container.reset-password-request-modal {
    .password-reset-section {
        #submitEmailButton {
            margin-top: rem(40);
            font-size: $font-size-14;
            border: rem(1) solid $primary-black;
        }
    }
}