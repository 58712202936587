.login-banner {
    background-position: unset;
    background-size: cover;
    background-repeat: no-repeat;
    height: rem(304);
    background-image: url(../images/account_mbl.png);

    @include media-breakpoint-up(md) {
        height: rem(232);
        background-image: url(../images/account.png);
    }
}

.login-page {
    margin-top: 0;
    padding-top: 0;

    // Adding below CSS to remove additional space in mobile due to equal height
    @include media-breakpoint-down(sm) {
        .row.equal-height>[class^=col] {
            display: block;
        }
    }

    @include media-breakpoint-up(md){
        padding-left: rem(80);
        padding-right: rem(81);
    }
    .login-form-nav {
        //added important to overwrite OOTB dynamic styles
        position: initial !important;
        .alert-danger {
            margin-top: rem(16);
        }
    }

    .card {
        border: none;

        @include media-breakpoint-up(md) {
            padding: 0 0 rem(107) 0;
            margin-bottom: rem(85);
        }
    }

    .card-body {
        padding: 0;
    }
    
    .card-header {
        border-bottom: rem(0.5) solid $primary-black;
        padding: 0 0 rem(8);

        @include media-breakpoint-up(md){
        padding: 0 0 rem(12);  
        }
    }

    .card-header-custom,
    .track-order-header {
        font-family: $font-medium;
        font-size: $font-size-3;
        line-height: rem(20);
        letter-spacing: 0.08em;
        color: $primary-black;
        text-transform: uppercase;
        margin: rem(16) 0 0;
        font-weight: $font-weight-medium;
    }

    .returning-customers {
        margin-top: rem(32);
        padding-right: 0;

        @include media-breakpoint-up(md) {
            margin-top: rem(40);
        }

        @include media-breakpoint-down(sm) {
            padding-left: rem(12);
            padding-right: rem(12);
        }
        .info-text {
            margin-top: rem(8);
            line-height: rem(20);
            letter-spacing: rem(0.8);
        
        @include media-breakpoint-up(md) {
            margin-top: rem(12);
        }    
        }
        
        .mail-text{
            font-size: $font-size-12;
            letter-spacing: rem(0.6);  
        }
        .text-password{
            font-size: $font-size-12;
            letter-spacing: rem(0.6);
        }
        .remember-me {
            margin-top: rem(24);
            line-height: rem(16);
            letter-spacing: rem(1.28);
            font-size: $font-size-12;
            padding-left: rem(24);

            @include media-breakpoint-down(md) {
                margin-bottom: 0;
            }

            .custom-control-label {
                line-height: rem(16);
                padding-top: rem(4);
            }
        }
        }
        .submit-signin{
            line-height: rem(20);
            letter-spacing: rem(1.28);
        }
        .button-secondary,
        .button-primary {
            margin-top: rem(40);

            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }
    }

    .forgot-password,
    .privacy-policy {
        margin-top: rem(22);
        text-align: center;

        @include media-breakpoint-up(md){
            margin-top: rem(38);
        } 
    }

    .new-customers {
        margin-top: rem(32);
        padding-right: 0;

        @include media-breakpoint-up(md) {
            margin-top: rem(40);
        }

        @include media-breakpoint-down(sm) {
            padding-left: rem(12);
            padding-right: rem(12);
        }

        .content-asset {
            font-size: $font-size-14;
            line-height: rem(20);
            font-family: $font-primary;


            p {
                margin: rem(8) 0 0;
                letter-spacing: rem(0.8);
                
                @include media-breakpoint-up(md){
                    margin: rem(12) 0 0;
                }

            }

            ul {
                margin-top: rem(40);

                @include media-breakpoint-down(sm) {
                    margin-top: rem(32);
                }
            }
        }
        .submit-account{
            letter-spacing: rem(1.28);
            line-height: rem(20);
        }
        
        .button-secondary {
            margin-top: rem(40);
            
            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }
    }
    
    .tertiary-link {
        text-transform: uppercase;
        text-decoration: underline;
        letter-spacing: rem(1.28);
        color: $primary-black;
        font-family: $font-medium;
    }

    .checkorder-status {
        margin-top: rem(32);
        margin-bottom: rem(56);
        
        .submit-status{
        
            line-height: rem(20);
            letter-spacing: rem(1.28);
            margin-top: rem(12);
        }
        .status-e-mail{
            .float-label{
            font-size: $font-size-12;
            letter-spacing: rem(0.6);
            }
            .form-group {
                margin-top: rem(24);
            }
        }
        .status-order{
            font-size: $font-size-12;
            letter-spacing: rem(0.6);
        }
        .status-billing{
            .float-label{
            font-size: $font-size-12;
            letter-spacing: rem(0.6);

            }
         }
        .form-group{
            margin-bottom: rem(8);
        }
        @include media-breakpoint-up(md) {
            margin-top: rem(40);
            margin-bottom: 0;
        }

        @include media-breakpoint-down(sm) {
            padding-left: rem(12);
            padding-right: rem(12);
        }

        .button-secondary,
        .button-primary {
            margin-top: rem(52);

            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }
    }

    .track-order-header-text {
        margin-top: rem(8);
        line-height: rem(20);
        letter-spacing: rem(0.8);

        @include media-breakpoint-up(md) {
            margin-top: rem(12);
        }
    }

    .create-account-btn {
        text-align: center;
        @include media-breakpoint-down(md) {
            margin-top: rem(40);
            line-height: rem(48);
        }
    }

    @include media-breakpoint-up(md) {

        .btn.button-secondary,
        .btn.button-primary {
            position: absolute;
            bottom: rem(48);
        }

        .forgot-password,
        .privacy-policy {
            position: absolute;
            bottom: rem(-5);
            text-align: center;
            width: 100%;
        }
    }

    .reset-password-container {
        .btn-save {
            font-family: $font-medium
        }
    }
    .reset-password-success {
        .close-modal-btn {
            font-family: $font-medium;
        }
    }

    .request-password-body {
        .form-group.custom {
            margin-top: 0;
        }
    }