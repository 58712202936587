.toggle-password {
    position: absolute;
    top: rem(28);
    right: 0;
    opacity: 0;

    .eye-active-icon {
        display: none;
        margin-top: rem(-7.3);
        width: rem(19.2);
        height: rem(16.9);
    }

    .eye-inactive-icon {
        display: inline-block;
        margin-top: rem(-7.3);
        width: rem(19.2);
        height: rem(16.9);
    }

    &.show-password {
        .eye-active-icon {
            display: inline-block;
        }

        .eye-inactive-icon {
            display: none;
        }
    }

    &:focus {
        opacity: 1;
    }
}

.toggle-password-input:focus+label+.toggle-password {
    opacity: 1;
}

.toggle-password-input {
    &::-ms-reveal {
        visibility: hidden;
    }
}

.toggle-password-input {
    &::-webkit-textfield-decoration-container {
        visibility: hidden;
    }
}

.toggle-password-input {
    &::-ms-clear {
        visibility: hidden;
    }
}

.valid-password-info-title {
    margin-bottom: 0;
    margin-top: rem(16);
}

.valid-password-info {
    list-style-type: disc;
    padding-left: rem(16);

    li {
        p {
            font-family: $font-primary;
            font-size: $font-size-12;
            letter-spacing: rem(0.4);
            line-height: rem(16);
            margin-bottom: 0;
        }
    }
}

.valid-password-info {
    padding-left: rem(13);
}

.valid-password-info-title,
.valid-password-info {
    font-family: $font-primary;
    font-size: $font-size-12;
    letter-spacing: rem(0.8);
    font-weight: $font-weight-regular;
    line-height: rem(16);
    color: $primary-black;;

    &.padding-none {
        padding: 0;
    }

    li {
        padding: rem(0) 0;
    }

    .error-in-pattern,
    .matched-pattern {
        display: none;
        padding-right: rem(9);
    }

    .pwd-criteria-not-matched {
        color: $monza;
        list-style-type: none;

        .error-in-pattern {
            display: inline-block;
        }

        svg {
            fill: $monza;
        }
    }

    .pwd-criteria-matched {
        list-style-type: none;

        .matched-pattern {
            display: inline-block;
        }
    }
}