.reset-password-container {
    margin-top: rem(80);
    letter-spacing: 0.05em;
    margin-bottom: rem(64);
    padding: 0 rem(16);

    .reset-password-title {
        h2 {
            margin-bottom: 0;
            font-family: $font-primary;
            font-weight: $font-weight-regular;
            font-size: $font-size-4;
            line-height: rem(24);
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: $primary-black;

            @include media-breakpoint-up(md) {
                font-size: $font-size-5;
                line-height: rem(28);
                letter-spacing: 0.08em;
            }
        }
    }

    .reset-password-fields {
        margin-top: rem(40);

        .form-group.custom {
            margin-top: rem(16);

            .float-label {
                line-height: rem(16);
                margin-bottom: rem(8);
            }

            .form-control {
                border-bottom: rem(0.5) solid $primary-black;
            }

            .invalid-feedback {
                margin-top: rem(8);
            }
        }
    }

    .btn-save {
        margin-top: rem(40);
        line-height: rem(20);
        letter-spacing: 0.08em;
        position: static;
    }

    @include media-breakpoint-up(md) {
        margin-top: rem(96);
    }

    .valid-password-info {
        padding-left: rem(13);
        margin-top: 0;
        letter-spacing: 0.05em;
    }

    .valid-password-info-title {
        margin-top: rem(16);
    }

    .valid-password-info-title,
    .valid-password-info {
        font-family: $font-primary;
        font-size: rem(12);
        letter-spacing: rem(0.4);
        line-height: rem(16);
        color: $primary-black;

        &.padding-none {
            padding: 0;
        }

        li {
            padding: rem(4) 0;
        }

        .error-in-pattern,
        .matched-pattern {
            display: none;
            padding-right: rem(9);
        }

        .pwd-criteria-not-matched {
            color: $form-group-error-color;
            list-style-type: none;

            .error-in-pattern {
                display: inline-block;
            }

            svg {
                fill: $form-group-error-color;
            }
        }

        .pwd-criteria-matched {
            list-style-type: none;

            .matched-pattern {
                display: inline-block;
            }
        }
    }
}